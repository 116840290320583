import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';
import { TdsDropdownCustomEvent } from '@scania/tegel';

import { useUpdateDefaultDashboardMutation } from '../../state/views/query';
import '../../styles/Overview.css';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';

type Props = {
  views: View[];
  updateSelectedView: Function;
  placeholder?: string;
  labelPosition?: 'inside' | 'outside';
  label?: string;
  size?: 'md' | 'xs' | 'sm' | 'lg';
  modeVariant?: 'primary' | 'secondary';
};

const isGlobalDashboard = (dashboard: View) => dashboard.permission?.scope.toLowerCase() === 'global'

// Function to preprocess views: group, sort, and add dummy rows
const preprocessDashboards = (dashboards: View[], isDashboardRoute: boolean, t: Function) => {
  const groupedDashboards = {
    global: dashboards.filter((dashboard) => isGlobalDashboard(dashboard)),
    custom: dashboards.filter((dashboard) => !isGlobalDashboard(dashboard)),
  };

  const sortedGlobalDashboards = groupedDashboards.global.sort((a, b) => {
    const translatedA = t(a.title.text) as string;
    const translatedB = t(b.title.text) as string;
    return translatedA.localeCompare(translatedB)
  });

  const sortedCustomDashboards = groupedDashboards.custom.sort((a, b) => a.title.text.localeCompare(b.title.text));

  const myDashboardsTitle = isDashboardRoute ? t('VP_MyDashboards') : t('VP_ViewsCustom');
  const defaultDashboardsTitle = isDashboardRoute ? t('VP_DefaultDashboards') : t('VP_ViewsDefault');

  if(groupedDashboards.custom.length < 1) {
    return [...sortedGlobalDashboards]
  }

  return [
    { dashboardReference: 'dummy-non-translation', title: { text: myDashboardsTitle, isTranslationKeyId: false } },
    ...sortedCustomDashboards,
    { dashboardReference: 'dummy-translation', title: { text: defaultDashboardsTitle, isTranslationKeyId: true } },
    ...sortedGlobalDashboards,
  ];
};

const ViewsDropdown = ({
  views = [],
  updateSelectedView,
  placeholder,
  labelPosition,
  label,
  size,
  modeVariant,
}: Props) => {
  const defaultSelectedView = views.find((view) => view.isDefault === true) || views[0];
  const viewsDropdownRef = useRef<HTMLTdsDropdownElement>(null);
  const [updateDefaultDashboard] = useUpdateDefaultDashboardMutation();
  const { t } = useTranslation();
  const location = useLocation();

  const isDashboardRoute = location.pathname.includes('dashboard');

  const handleSelectOption = useCallback(
    (e: TdsDropdownCustomEvent<{ name: string; value: string }>) => {
      const currentDefaultOption = views.find((view) => view.isDefault);
      const selectedOption = views.find(
        (view) => view.dashboardReference === e.detail.value
      );

      if (currentDefaultOption && selectedOption && currentDefaultOption.dashboardReference !== selectedOption.dashboardReference) {
        updateDefaultDashboard(selectedOption.dashboardReference);
      }
      if(e.detail.value !== selectedOption?.dashboardReference) {
        updateSelectedView(e.detail.value);
      }
    },
    [updateDefaultDashboard, updateSelectedView, views]
  );

  useEffect(() => {
    const viewsDropdown = viewsDropdownRef.current;
    if (!viewsDropdown) return;

    viewsDropdown.addEventListener('tdsChange', handleSelectOption);

    return () => {
      viewsDropdown.removeEventListener('tdsChange', handleSelectOption);
    };
  }, [handleSelectOption]);

  const processedViews = preprocessDashboards(views, isDashboardRoute, t)

  return (
    <div className='viewsDropdownContainer'>
      <TdsDropdown
        ref={viewsDropdownRef}
        placeholder={placeholder || t('Välj')}
        defaultValue={defaultSelectedView.dashboardReference}
        label={label || t('ÄndraVy')}
        label-position={labelPosition || 'outside'}
        modeVariant={modeVariant || 'primary'}
        size={size || 'md'}>
        {processedViews.map((view) => (
          <TdsDropdownOption
            key={view.dashboardReference}
            value={view.dashboardReference}
            className={view.dashboardReference.startsWith('dummy-') ? 'isDropdownTitle' : ''}
            >
            <span className={view.dashboardReference.startsWith('dummy-') ? 'isDropdownTitleText' : ''}>
                {view.dashboardReference.startsWith('dummy-')
                ? (view.title.isTranslationKeyId
                ? stripNonAlphaCharacters(t(getNameTranslationKey(view.title.text))).toUpperCase()
                : view.title.text.toUpperCase())
                : (view.title.isTranslationKeyId
                ? stripNonAlphaCharacters(t(getNameTranslationKey(view.title.text)))
                : view.title.text)}
            </span>
          </TdsDropdownOption>
        ))}
      </TdsDropdown>
    </div>
  );
};

export default ViewsDropdown;
