import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsButton, TdsModal, TdsSpinner, TdsTextField } from '@scania/tegel-react';

import { useCreateDashboardMutation, useUpdateDashboardMutation } from '../../state/views/query';
import { uuidv4 } from '../../utils/api';

import styles from '../../styles/ViewModal.module.css';

type DashboardModalProps = {
  header: string;
  selector: string;
  dashboardDetails: DashboardDetails | null;
  selectedDashboardRef: string | null;
  pushNotification: Function;
  updateSelectedDashboard: Function;
  setSelector: Function;
};

const DashboardModal = ({
  header,
  selector,
  dashboardDetails = null,
  selectedDashboardRef,
  pushNotification,
  updateSelectedDashboard,
  setSelector
}: DashboardModalProps) => {
  const { t } = useTranslation();

  const [createDashboard, { isLoading: isSavingDashboard }] = useCreateDashboardMutation();
  const [updateDashboard, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();

  const [dashboardNameValue, setDashboardNameValue] = useState<string>(dashboardDetails && selector === 'editDashboard' ? dashboardDetails.title.text : '');
  const [dashboardNameError, setDashboardNameError] = useState(false);
  
  const dashboardNameRef = useRef<HTMLTdsTextFieldElement>(null);
  const dashboardModalRef = useRef<HTMLTdsModalElement>(null);
  
  const handleNameChange = useCallback(
    (e: any) => {
      const currentDashboardName = e.detail?.target?.value?.trim() || '';
      setDashboardNameValue(currentDashboardName);
      const errorStatus = !(currentDashboardName.length > 0);
      if (errorStatus !== dashboardNameError) {
        setDashboardNameError(errorStatus);
      }
    },
    [dashboardNameError]
  );

  useEffect(() => {
    const nameTextField = dashboardNameRef.current;
    if (!nameTextField) return;

    nameTextField.addEventListener('tdsInput', handleNameChange);

    return () => {
      nameTextField.removeEventListener('tdsInput', handleNameChange);
    };
  }, [handleNameChange]);
  
  const clearDashboardData = useCallback(() => {
    setSelector('');
    setDashboardNameValue('');

    if (dashboardNameError) {
      setDashboardNameError(false);
    }
  }, [dashboardNameError, setSelector]);

  useEffect(() => {
    const viewModal = dashboardModalRef.current;
    viewModal?.addEventListener('tdsClose', clearDashboardData);
    return () => viewModal?.removeEventListener('tdsClose', clearDashboardData);
  }, [clearDashboardData]);
  
  useEffect(() => {
    if (selector?.length > 0 &&
      selector === 'editDashboard' &&
      dashboardDetails?.title) {
      setDashboardNameValue(dashboardDetails.title?.text || '');
    }
  }, [dashboardDetails, selector]);

  const saveDashboard = async () => {
    const isEditing = selector === 'editDashboard';

    const currentDashboardName = dashboardNameRef.current?.value?.trim() || '';
    setDashboardNameError(currentDashboardName.length === 0);

    if (currentDashboardName.length > 0) {
      // name is correct, we can save
      const tempPayload: DashboardDetails = {
        dashboardReference: isEditing && selectedDashboardRef && selectedDashboardRef?.length > 0 ? selectedDashboardRef : undefined,
        properties: null, // dashboard-level filters to be added when they'll be available 
        title: {
          text: currentDashboardName,
          isTranslationKeyId: false,
        },
        widgets: isEditing && dashboardDetails && dashboardDetails.widgets ? dashboardDetails.widgets : [],
      };
      
      try {
        const result = isEditing ? await updateDashboard(tempPayload).unwrap() :  await createDashboard(tempPayload).unwrap()
        pushNotification({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: isEditing ? t('VP_DashboardEdited') : t('VP_DashboardCreated'),
          variant: 'success',
        });

        dashboardModalRef.current?.closeModal();
        clearDashboardData();
        updateSelectedDashboard(result.dashboardReference);          
      } catch (err) {
        pushNotification({
          id: uuidv4(),
          header: t('Failed'),
          subheader: isEditing ? t('VP_DashboardNotEdited') : t('VP_DashboardNotCreated'),
          variant: 'error',
        });
      }
    }
  }

  const currentDashboardModal = dashboardModalRef.current;
  if (selector.length && currentDashboardModal) {
    currentDashboardModal.showModal();
  }
  
  return (
    <TdsModal
      selector={(selector?.length > 0) ? `#${selector}` : ''}
      size='sm'
      prevent={true}
      actions-position='sticky'
      ref={dashboardModalRef}>
      <span slot='header' style={{ height: '24px' }}>{t(header)}</span>
      <span slot='body'>
        <TdsTextField
          type='text'
          size='md'
          ref={dashboardNameRef}
          label={t('VP_DashboardName')}
          label-position='outside'
          placeholder={t('VP_DashboardName')}
          state={dashboardNameError ? 'error' : 'default'}
          helper={t('_Obligatorisk')}
          value={dashboardNameValue}
        />
      </span>
        <span slot='actions' className={styles.actionButtons}>
            <div className={styles.rightSideButtons}>
              <TdsButton
                data-dismiss-modal
                size='sm'
                text={t('Avbryt')}
                type='button'
                variant='secondary'
              />
              {isSavingDashboard || isUpdatingDashboard ? (
                <div className={styles.buttonSpinner}>
                  <TdsSpinner size='sm' />
                </div>
              ) : (
                <TdsButton
                  size='sm'
                  text={selector === 'editDashboard' ? t('Spara') : t('Skapa')}
                  type='button'
                  disabled={dashboardNameError ||
                    (!dashboardNameError && dashboardNameValue.length === 0) ||
                    undefined
                  }
                  onClick={saveDashboard}
                />
              )}
            </div>
        </span>
    </TdsModal>
  )
}

export default DashboardModal;