import { TdsButton, TdsIcon } from '@scania/tegel-react';
import { useTranslation } from 'react-i18next';
import TableWidget from '../common/TableWidget';
import KPICard from '../common/KPICard';
import '../../styles/DashboardWidgets.css';

type DashboardWidgetsProps = {
  staffDetails: StaffDetails;  
  periodStart: string;
  periodEnd: string;
  selectedGroup?: string;
  selectedDashboardSpecs: DashboardDetails;
  externalEquipmentRef?: string | null;
  fuelTypes: string[];
  vehicleTypes: string[];
  updatedDashboardFilters: boolean;
  setWidgetModalSelector: Function;
  setWidgetReferenceToEdit: Function;
  isSelectedDashboardNotEditable: boolean;
}

const DashboardWidgets = ({
  staffDetails,
  periodStart,
  periodEnd,
  selectedGroup = 'groupFilterAll',
  selectedDashboardSpecs,
  externalEquipmentRef,
  fuelTypes,
  vehicleTypes,
  updatedDashboardFilters,
  setWidgetModalSelector,
  setWidgetReferenceToEdit,
  isSelectedDashboardNotEditable = true
}: DashboardWidgetsProps) => { 
  const { t } = useTranslation();

  return <>    
    { (!selectedDashboardSpecs.widgets ||
      (selectedDashboardSpecs.widgets && selectedDashboardSpecs.widgets.length === 0)) &&
      <div className='emptyDashboardContainer'>
        <div className='emptyDashboardContent'>
          <div><TdsIcon size='24px' name='star' /></div>
          <div className='rightSection'>
            <div className='tds-headline-04 emptyDashboardTitle'>{t('Welcome to your dashboard!')}</div>
            <div className='tds-detail-01'>{t('Start adding widgets to your dashboard to monitor key metrics.')}</div>
            <div className='addWidgetButton'>
              <TdsButton type='button' variant='secondary' size='sm' text={t('VP_AddWidget')} mode-variant='primary' onClick={() => setWidgetModalSelector('createWidgetButton')}>
                <TdsIcon slot='icon' size='16px' name='plus' />
              </TdsButton>
            </div>
          </div>
        </div>
      </div>
    }
    { selectedDashboardSpecs.widgets?.length > 0 && updatedDashboardFilters &&
      <>
        <div className='kpiWidgetsContainer'>
          {selectedDashboardSpecs?.widgets?.filter((singleWidget: { view: string; }) => singleWidget?.view?.toLowerCase() === 'singlestatistic')?.map((singleWidget: any, singleWidgetIndex: number) => (
            <div className='kpiContainer'
                key={`singleKPIWidget_${singleWidgetIndex}`}>
              <KPICard
                staffDetails={staffDetails}
                parameter={singleWidget.properties.parameter}
                statistic={singleWidget.properties.statistic}
                queryStart={periodStart}
                queryStop={periodEnd}
                selectedGroup={selectedGroup}
                externalEquipmentRef={externalEquipmentRef}
                fuelTypes={fuelTypes}
                vehicleTypes={vehicleTypes}
                widgetIndex={singleWidgetIndex}
              />
            </div>
          ))}
        </div>
        <div className='tableWidgetsContainer'>
          {selectedDashboardSpecs?.widgets?.filter((singleWidget: { view: string; }) => singleWidget?.view?.toLowerCase() === 'table')?.map((singleWidget: any, singleWidgetIndex: number) => (
            <TableWidget
              key={`singleTableWidget_${singleWidgetIndex}`}
              widgetIndex={singleWidgetIndex}
              staffDetails={staffDetails}
              tableWidgetSpecs={singleWidget}
              periodStart={periodStart}
              periodEnd={periodEnd}
              selectedGroup={selectedGroup}
              externalEquipmentRef={externalEquipmentRef}
              fuelTypes={fuelTypes}
              vehicleTypes={vehicleTypes}
              isSelectedDashboardNotEditable={isSelectedDashboardNotEditable}
              setWidgetModalSelector={setWidgetModalSelector}
              setWidgetReferenceToEdit={setWidgetReferenceToEdit}
            />
          ))}
        </div>
      </>
    }
  </>
}

export default DashboardWidgets;