import {
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';

import '../../styles/GeneralStyles.css';

type Props = {
  selectedOptions: string[];
  onSelection: Dispatch<SetStateAction<string[]>>;
  externalEquipmentReference: string | null;
  labelPosition?: 'inside' | 'outside';
  modeVariant?: 'primary' | 'secondary';
  minWidth?: string;
};


const FuelTypeDropdown = ({ selectedOptions = [], onSelection, externalEquipmentReference, labelPosition, modeVariant, minWidth }: Props) => {
  const { t } = useTranslation();
  const fuelTypeDropdownRef = useRef<HTMLTdsDropdownElement>(null);
  
  const fuelOptions = [
    { key: 'diesel', value: 'diesel', label: t('Diesel') },
    { key: 'ethanol', value: 'ethanol', label: t('Etanol') },
    { key: 'gas', value: 'gas', label: t('Gas') },
    { key: 'electric', value: 'electric', label: t('Electricitet') }
  ];

  useEffect(() => {
    if (selectedOptions.length === 0) {
      fuelTypeDropdownRef.current?.reset();
    } else {
      fuelTypeDropdownRef.current?.setValue(selectedOptions);
    }
  }, [selectedOptions]);

  const handleFuelTypeSelection = useCallback(
    (e: any) => {
      let optionsCopy = [...selectedOptions];

      if (e.detail.selected && !optionsCopy.includes(e.detail.value)) {
        optionsCopy.push(e.detail.value);
      } else {
        optionsCopy = optionsCopy.filter((option) => option !== e.detail.value);
      }

      onSelection(optionsCopy);
    },
    [onSelection, selectedOptions]
  );

  useEffect(() => {
    const fuelTypeDropdown = fuelTypeDropdownRef.current;
    if (!fuelTypeDropdown) return;

    fuelTypeDropdown.addEventListener('tdsSelect', handleFuelTypeSelection);

    return () => {
      fuelTypeDropdown.removeEventListener(
        'tdsSelect',
        handleFuelTypeSelection
      );
    };
  }, [handleFuelTypeSelection]);

  return (
    <div className='typeDropdownContainer' style={{minWidth: minWidth}}>
      <TdsDropdown
        ref={fuelTypeDropdownRef}
        name='fuelTypes'
        multiselect
        modeVariant={modeVariant || 'primary'}
        size='md'
        placeholder={t('Välj')}
        label={t('TH_Bränsletyp')}
        label-position={labelPosition || 'outside'}
        disabled={externalEquipmentReference !== null}
        defaultValue={selectedOptions.toString()}>
          {fuelOptions.map((option) => (
            <TdsDropdownOption key={option.key} value={option.value}>
              {option.label}
            </TdsDropdownOption>
          ))}
      </TdsDropdown>
    </div>
  );
};

export default FuelTypeDropdown;
